.page {
	flex: 1;
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.main {
	width: 100%;
	overflow-y: auto;
	touch-action: pan-y;
}

.video {
	width: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	position: relative;
}

.main__info {
	padding: 15px;
	display: flex;
	flex-direction: row;
}

.main__userImage {
	height: 50px;
	width: 50px;
	border-radius: 50px;
	flex-shrink: 0;
	flex-grow: 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.main__info__text {
	margin-left: 15px;
}

.main__userName {
	font-size: 20px;
	line-height: 1;
	font-weight: bold;
}

.main__title {
	margin-top: 10px;
	font-size: 18px;
	line-height: 1;
}

.about {
	width: 100%;
	max-width: 800px;
	margin: 10px auto 0;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 40px 50px;
}

.about__title {
	font-size: 30px;
	font-weight: bold;
	text-align: center;
}

.about__grid {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.about__grid__left {
	flex-grow: 1;
}

.about__grid__right {
	text-align: right;
	flex-grow: 0;
	flex-shrink: 0;
}

.about__followers {
	opacity: 0.8;
}

.about__bio {
	margin-top: 10px;
}

.about__socialLink svg {
	display: inline-block;
	vertical-align: top;
	margin-left: 0.3em;
}

@media screen and (max-width: 600px) {
	.page {
		flex-direction: column;
	}

	.main {
		flex-shrink: 0;
	}

	.about {
		padding: 10px;
	}

	.about__title {
		font-size: 20px;
	}

	.about__grid {
		margin-top: 10px;
		display: block;
		text-align: center;
	}

	.about__grid__right {
		margin-top: 10px;
		text-align: center;
	}
}

.player {
	position: relative;
	height: 0;
	padding: 28.125% 0;
}

.player__video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
}

.player__video > video {
	object-fit: cover;
}

.playButton {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity 0.2s, background-color 0.2s;
	cursor: pointer;
	pointer-events: none;
	z-index: 1;
	padding: 8px 8px 4px 10px;
	border-radius: 5px;
	font-size: 90px;
	text-align: center;
	line-height: 1;
}

.playButton:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.playButton.visible {
	opacity: 1;
	pointer-events: auto;
}

.playButton__ended {
	font-size: 16px;
}

.controls {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	padding: 5px;
	opacity: 0;
	transition: opacity 0.2s;
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.5) 0,
		rgba(0, 0, 0, 0.25) 60%,
		transparent
	);
}

.player:hover .controls,
.controls.visible {
	opacity: 1;
}

.controls__button {
	display: inline-block;
	padding: 5px;
	cursor: pointer;
	transition: background-color 0.2s;
	border-radius: 5px;
	margin-right: 5px;
}

.controls__button:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.live {
	position: absolute;
	left: 10px;
	top: 10px;
	color: white;
	background-color: red;
	border-radius: 5px;
	font-size: 13px;
	line-height: 1;
	font-weight: bold;
	padding: 4px 6px;
}

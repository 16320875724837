.chat {
	display: flex;
	flex-direction: column;
	width: 340px;
	height: 100%;
	flex-shrink: 0;
	flex-grow: 0;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	background-color: #18181b;
}

.chat__expand {
	position: absolute;
	right: 15px;
	top: 65px;
	font-size: 20px;
	transition: background-color 0.2s;
	cursor: pointer;
	height: 35px;
	width: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 5px;
	text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.chat__expand:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.header {
	color: rgba(255, 255, 255, 0.9);
	text-align: center;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	line-height: 1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	flex-shrink: 0;
	flex-grow: 0;
	position: relative;
}

.header__collapse {
	position: absolute;
	left: 10px;
	top: 10px;
	height: 30px;
	width: 30px;
	line-height: 30px;
	text-align: center;
	border-radius: 5px;
	transition: background-color 0.2s;
	cursor: pointer;
}

.header__collapse:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.chat__content {
	position: relative;
	overflow: hidden;
	flex: 1;
}

.chat__scroller {
	padding: 0 15px 10px 15px;
	overflow-y: auto;
	position: relative;
	height: 100%;
	width: 100%;
}

.message {
	padding-top: 15px;
	line-height: 1.2;
}

.message.system {
	opacity: 0.7;
}

.message__user {
	font-weight: bold;
	color: red;
}

.message__text,
.message__text > p {
	display: inline;
}

.moreMessages {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	padding: 5px 15px;
	border-radius: 15px;
	cursor: pointer;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s;
	text-align: center;
	white-space: nowrap;
}

.moreMessages.visible {
	opacity: 1;
	pointer-events: auto;
}

.form {
	height: 50px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	flex-shrink: 0;
	flex-grow: 0;
	position: relative;
}

.form__textbox {
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	border: none;
	color: #fff;
	padding: 0 60px 0 10px;
	font-size: 15px;
}

.form__submit {
	background-color: #04d9ff;
	color: black;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	padding: 0 10px;
	border: none;
	border-radius: 5px;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.chat {
		width: 100%;
		height: auto;
		flex: 1;
		overflow: hidden;
		border-left: none;
	}

	.header {
		display: none;
	}

	.form {
		border-top: none;
	}

	.form__textbox {
		border-radius: 0;
	}
}

.grid {
	margin-top: 20px;
}

.grid:first-child {
	margin-top: 0;
}

.grid__title {
	font-size: 24px;
}

.grid__grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
	margin-top: 10px;
	overflow-y: hidden;
	padding-bottom: 10px;
}

.item {
	display: block;
	position: relative;
}

.item__frameWrapper {
	width: 100%;
	height: 0;
	padding: 28.125% 0;
	position: relative;
	background-color: rgba(255, 255, 255, 0.1);
}

.item__image {
	width: 100%;
	padding: 28.125% 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.item__info {
	padding-top: 10px;
	display: flex;
	flex-direction: row;
}

.item__userImage {
	height: 50px;
	width: 50px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 50%;
	flex-shrink: 0;
	flex-grow: 0;
}

.item__info__text {
	margin-left: 10px;
}

.item__title {
	font-size: 16px;
	color: #fff;
}

.item__userName {
	opacity: 0.8;
	font-size: 14px;
	color: #fff;
}

.item__live {
	position: absolute;
	left: 5px;
	top: 5px;
	color: white;
	background-color: red;
	border-radius: 5px;
	font-size: 13px;
	line-height: 1;
	font-weight: bold;
	padding: 4px 6px;
}

.item__externalFrame {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	.grid__grid {
		display: block;
		width: 100%;
		overflow-x: auto;
		white-space: nowrap;
	}

	.grid__grid::-webkit-scrollbar {
		width: 0px; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}

	.item {
		display: inline-block;
		vertical-align: top;
		width: 70% !important;
		background-color: rgba(255, 255, 255, 0.1);
		margin-right: 10px;
	}

	.item:last-child {
		margin-right: 0;
	}

	.item__info {
		padding: 10px;
		height: 55px;
	}

	.item__info__text {
		overflow: hidden;
	}

	.item__userImage {
		width: 30px;
		height: 30px;
	}

	.item__title {
		line-height: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media screen and (max-width: 600px) {
	.grid {
		margin-top: 0;
	}
}

@media screen and (max-width: 359px) {
	.grid__title {
		font-size: 20px;
	}
}

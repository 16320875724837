.modal {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(10px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.5s ease-out;
}

.modal.enter {
	opacity: 1;
}

.modal__card {
	width: 90%;
	max-width: 600px;
	max-height: 90%;
	background-color: white;
	color: #333;
	border-radius: 20px;
	padding: 30px 0;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
	transform: scale(0.1);
	transition: transform 0.5s ease-out;
}

.modal__card.enter {
	transform: scale(1);
}

.modal__card.noPadding {
	padding: 0;
}

.modal__contents {
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 20px;
}

.noPadding > .modal__contents {
	padding: 0;
}

.modal__closeButton {
	position: absolute;
	right: 0;
	top: 0;
	padding: 10px 16px;
	font-size: 24px;
	line-height: 1;
	font-weight: bold;
	cursor: pointer;
}

.modal__title {
	font-weight: bold;
	text-align: center;
	font-size: 24px;
}

.modal__text {
	font-size: 18px;
	text-align: left;
	width: 100%;
}

.modal__center {
	text-align: center;
}

.button {
	display: inline-block;
	background-color: #04d9ff;
	color: black;
	line-height: 30px;
	border-radius: 5px;
	padding: 5px 15px;
	cursor: pointer;
	white-space: nowrap;
}

.button.inline {
	display: inline-block;
	vertical-align: top;
}

.header {
	height: 50px;
	width: 100%;
	flex-shrink: 0;
	flex-grow: 0;
	position: relative;
	z-index: 100;
	padding: 0 20px 0 12px;
	display: flex;
	align-items: center;

	background-color: #18181b;
	box-shadow: rgba(0, 0, 0, 0.9) 0px 1px 2px 0px,
		rgba(0, 0, 0, 0.9) 0px 0px 2px 0px;
}

.header :global(.Logo) {
	text-align: left;
	margin-top: 5px;
}

.link {
	font-size: 25px;
	margin-left: 30px;
	color: #fff;
}

.link:hover {
	color: #04d9ff;
}

.right {
	position: absolute;
	right: 0;
	top: 0;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-right: 20px;
}

.logoutButton {
	display: inline-block;
	background-color: #04d9ff;
	color: black;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	padding: 0 10px;
	cursor: pointer;
	white-space: nowrap;
}

@media screen and (max-width: 600px) {
	.header {
		padding: 0 10px 0 3px;
	}
	.right {
		padding-right: 10px;
	}
}
